<template>
    <div>
        <!-- <div class="mys-home-header">
            <div class="title">首页</div>
        </div> -->
        <!-- <div class="mys-home-large-card mys-home-large-card-top">
            <div class="title">Scc Maker创集荟</div>
            <div class="subtitle">Cultural creations of Scc</div>
            <router-link to="/Shop/Product/">
                <wd-button size="small" class="button">浏览商品</wd-button>
            </router-link>
        </div> -->
        <!-- <wd-card style="margin-top: 16px;"> -->
        <!-- <span slot="title" style="font-size: 18px; font-weight: 600;">
                线上预约平台
            </span> -->
        <!-- </wd-card> -->
        <wd-swipe>
            <wd-swipe-item v-for="item in bannerData" :key="item" style="border-radius: 4px;">
                <img :src="require('../assets/images/' + item.image)"
                    style="max-height: 240px; width: 100%; object-fit: cover;" />
            </wd-swipe-item>
        </wd-swipe>
        <router-link to="/Reservation/Personal/">
            <div class="mys-home-large-card mys-home-large-card-content">
                <wd-row>
                    <wd-col span="4" style="margin-right: 12px;">
                        <img src="../assets/icons/personal.svg" style="max-height: 52px; max-width: 52px;" />
                    </wd-col>
                    <wd-col span="18">
                        <div class="title">个人预约</div>
                        <div class="subtitle">Personal Reservation</div>
                        <!-- <router-link to="/Reservation/New/" style="margin-right: 6px">
                            <wd-button size="small" class="button">新增预约</wd-button>
                        </router-link>
                        <router-link to="/Reservation/List/">
                            <wd-button size="small" class="button">查看预约</wd-button>
                        </router-link> -->
                    </wd-col>
                </wd-row>
            </div>
        </router-link>
        <router-link to="/Reservation/Group/">
            <div class="mys-home-large-card mys-home-large-card-content">
                <wd-row>
                    <wd-col span="4" style="margin-right: 12px;">
                        <img src="../assets/icons/group.svg" style="max-height: 52px; max-width: 52px;" />
                    </wd-col>
                    <wd-col span="18">
                        <div class="title">团体预约</div>
                        <div class="subtitle">Group Reservation</div>
                    </wd-col>
                </wd-row>
            </div>
        </router-link>
        <router-link to="/Reservation/Search/">
            <div class="mys-home-large-card mys-home-large-card-content">
                <wd-row>
                    <wd-col span="4" style="margin-right: 12px;">
                        <img src="../assets/icons/record.svg" style="max-height: 52px; max-width: 52px;" />
                    </wd-col>
                    <wd-col span="18">
                        <div class="title">预约记录</div>
                        <div class="subtitle">My Reservation</div>
                    </wd-col>
                </wd-row>
            </div>
        </router-link>
        <div class="mys-home-large-cards">
            <router-link to="/Description/" class="card">
                <div class="title">展馆简介
                </div>
                <div class="subtitle">Description</div>
            </router-link>
            <!-- <router-link to="/Direactions/" class="card"> -->
            <a href="https://q.eqxiu.com/s/DhcL1gGw" class="card">
                <div class="title">交通指南</div>
                <div class="subtitle">Direactions</div>
            </a>
            <!-- </router-link> -->
        </div>
        <div class="mys-home-large-cards">
            <router-link to="/Rules/" class="card">
                <div class="title">开放制度</div>
                <div class="subtitle">Rules</div>
            </router-link>
            <router-link to="/Notice/" class="card">
                <div class="title">参观导览</div>
                <div class="subtitle">Notice</div>
            </router-link>
        </div>
        <wd-message-box :show="showNoticeDialog" @action="showNoticeDialog = false" :title="noticeData.title">
            <div v-html="noticeData.content"></div>
        </wd-message-box>
    </div>
</template>

<script>
import axios from "axios";
import Common from "@/components/Common";
var apiurl = Common.APIurl;

export default {
    data() {
        return {
            showNoticeDialog: false,
            noticeData: {},
            show: true,
            bannerData: [
                {
                    name: '',
                    image: 'banner_1.jpg',
                },
                {
                    name: '',
                    image: 'banner_2.jpg',
                },
                {
                    name: '',
                    image: 'banner_3.jpg',
                },
                {
                    name: '',
                    image: 'banner_4.jpg',
                },
                // {
                //     name: '',
                //     image: 'banner_5.jpg',
                // }
            ]
        };
    },
    created() {
        axios
            .get(apiurl + "Notice/GetNotice", {
                params: {
                    type: 'home'
                },
            })
            .then((response) => {
                if (response.data.code == 0) {
                    this.showNoticeDialog = true;
                    this.noticeData = response.data.data;
                }
            })
            .catch((error) => {
                this.$toast.error(error.message);
            });
    },
};
</script>

<style>
.mys-home-header {
    padding: 32px 32px 64px 32px;
    /* background-color: #fff; */
    background-image: url(../assets/images/home-header.png);
    background-size: cover;
    display: flex;
    -webkit-box-align: start;
    min-height: 100px;
}

.mys-home-header .title {
    color: #fff;
    padding-bottom: 6px;
    font-size: 24px;
}

.mys-home-large-card-top {
    margin-top: -32px;
}

.mys-home-large-card-content {
    margin-top: 16px;
}

.mys-home-large-card {
    margin-left: 16px;
    margin-right: 16px;
    padding: 14px;
    /* min-height: 64px; */
    max-height: 64px;
    background: #fff;
    border-radius: 8px;
}

.mys-home-large-card .title {
    font-size: 24px;
    font-weight: 600;
}

.mys-home-large-card .subtitle {
    text-transform: uppercase;
    font-size: 12px;
}

.mys-home-large-card .button {
    margin-top: 6px;
}

.mys-home-large-cards {
    margin-top: 16px;
    padding: 0px 16px;
    display: flex;
    gap: 12px;
}

.mys-home-large-cards .card {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 50%;
    /* min-height: 64px; */
    float: left;
    padding: 14px;
    background: #fff;
    border-radius: 8px;
    /* box-shadow: 0 5px 5px rgb(77 128 240 / 20%); */
}

/* .mys-home-large-cards .card .title {
    font-size: 18px;
} */
.mys-home-large-cards .card .title {
    font-size: 24px;
    font-weight: 600;
}

.mys-home-large-cards .card .subtitle {
    text-transform: uppercase;
    font-size: 12px;
}

.mys-home-large-cards .card .button {
    margin-top: 6px;
}
</style>